import React, {
  createContext,
  Dispatch,
  ReactNode,
  useContext,
  useState,
} from "react";

import * as Api from "../components/API/Api";
import AuthContext from "./Auth";

export interface RemainingAccountInvitationsContextProps {
  remainingInvitationCount?: number;
  setRemainingInvitationCount?: Dispatch<any>;
  loadRemainingInvitationCount?: () => void;
}

const ChildListContext = createContext({
  remainingInvitationCount: undefined,
  setRemainingInvitationCount: undefined,
  loadRemainingInvitationCount: undefined,
} as RemainingAccountInvitationsContextProps);

interface RemainingAccountInvitationsProviderProps {
  children: ReactNode;
}

export const RemainingAccountInvitationsProvider = ({
  children,
}: RemainingAccountInvitationsProviderProps) => {
  const { accessToken } = useContext(AuthContext);

  const [remainingInvitationCount, setRemainingInvitationCount] =
    useState<number>();

  let isLoading = false;
  const loadRemainingInvitationCount = async () => {
    if (!accessToken || isLoading || remainingInvitationCount !== undefined) {
      return;
    }
    isLoading = true;
    try {
      const { RemainingInvitations: rem } =
        await Api.getRemainingAccountInvitations({ accessToken });
      setRemainingInvitationCount(rem);
    } catch (ex) {
      setRemainingInvitationCount(0);
    }

    isLoading = false;
  };

  return (
    <ChildListContext.Provider
      value={{
        remainingInvitationCount,
        setRemainingInvitationCount,
        loadRemainingInvitationCount,
      }}
    >
      {children}
    </ChildListContext.Provider>
  );
};
export default ChildListContext;

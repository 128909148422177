import React, {
  createContext,
  Dispatch,
  ReactNode,
  useContext,
  useState,
} from "react";

import { Child } from "../../../libs/ts/models/types";
import * as Api from "../components/API/Api";
import AuthContext from "./Auth";

export interface ChildListContextProps {
  childList?: Child[];
  setChildList?: Dispatch<any>;
  loadChildList?: () => void;
}

const ChildListContext = createContext({
  children: undefined,
  setChildList: undefined,
  isLoading: false,
  loadChildList: undefined,
} as ChildListContextProps);

interface ChildListProviderProps {
  children: ReactNode;
}

export const ChildListProvider = ({ children }: ChildListProviderProps) => {
  const { accessToken } = useContext(AuthContext);

  const [childList, setChildList] = useState();

  let isLoading = false;
  const loadChildList = async () => {
    if (!accessToken || isLoading || childList) {
      return;
    }
    isLoading = true;
    const { Children: list } = await Api.getAllChildren({ accessToken });
    setChildList(list);
    isLoading = false;
  };

  return (
    <ChildListContext.Provider
      value={{ childList, setChildList, loadChildList }}
    >
      {children}
    </ChildListContext.Provider>
  );
};
export default ChildListContext;

import { useVariableValue } from "@devcycle/devcycle-react-sdk";

interface UseFeatureFlagProps {
  flagName: string;
  defaultValue: any;
}

export default function useFeatureFlag({
  flagName,
  defaultValue,
}: UseFeatureFlagProps) {
  type defaultValueType = typeof defaultValue;
  const featureValue = useVariableValue(
    flagName,
    defaultValue
  ) as defaultValueType;

  return featureValue;
}

import { useAuth0 } from "@auth0/auth0-react";
import { useIsDVCInitialized } from "@devcycle/devcycle-react-sdk";
import React, { ReactElement } from "react";

import ScreenCover from "../ScreenCover/ScreenCover";
import Spinner from "../Spinner/Spinner";

// todo: this should be a component
const loadingIndicator = (
  <ScreenCover>
    <Spinner />
  </ScreenCover>
);

interface WhenReadyProps {
  children: ReactElement;
}

function WhenReady({ children }: WhenReadyProps) {
  const { isLoading: authLoading } = useAuth0();
  const dvcReady = useIsDVCInitialized();

  if (authLoading || !dvcReady) {
    return loadingIndicator;
  }
  console.log("SYSTEMS READY");
  return children;
}

export default WhenReady;

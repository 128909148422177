import "bootstrap/dist/css/bootstrap.css";
import "boxicons/css/boxicons.min.css";
import "../styles/globals.css";

import { Auth0Provider, CacheLocation } from "@auth0/auth0-react";
import { withDVCProvider } from "@devcycle/devcycle-react-sdk";
// Provider imports 'rollbar'
import Head from "next/head";
import { useRouter } from "next/router";
import React, { useEffect } from "react";
import ReactGA from "react-ga4";

import WhenReady from "../components/WhenReady/WhenReady";
import { AuthProvider } from "../contexts/Auth";
import { ChildListProvider } from "../contexts/ChildList";
import { RecentMemoriesProvider } from "../contexts/RecentMemories";
import { RemainingAccountInvitationsProvider } from "../contexts/RemainingAccountInvitations";
import { Rollbar } from "../contexts/Rollbar";
import { SignalProvider } from "../contexts/Signals";
import { ThemeProvider } from "../contexts/Theme";

const SITE_TITLE = process.env.NEXT_PUBLIC_SITE_TITLE ?? "MiniMemories";
const LOGIN_REDIRECT_URI = process.env.NEXT_PUBLIC_LOGIN_REDIRECT_URI ?? "";
const LOGIN_DOMAIN = process.env.NEXT_PUBLIC_LOGIN_DOMAIN ?? "";
const LOGIN_CLIENT_ID = process.env.NEXT_PUBLIC_LOGIN_CLIENT_ID ?? "";
const API_AUDIENCE = process.env.NEXT_PUBLIC_API_AUDIENCE ?? "";
const CACHE_LOCATION = (process.env.NEXT_PUBLIC_CACHE_LOCATION ??
  "memory") as CacheLocation;
const GOOGLE_ANALYTICS_TRACKING_ID =
  process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_TRACKING_ID ?? "";
const FACEBOOK_PIXEL_ID = process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID ?? "";
const DVC_CLIENT_SDK_KEY = process.env.NEXT_PUBLIC_DVC_CLIENT_SDK_KEY ?? "";

const googleAnalyticsHTML = `
  <script
    async
    src="https://www.googletagmanager.com/gtag/js?id=UA-222107388-1"
  ></script>
  <script>
    window.dataLayer = window.dataLayer || []; function gtag()
    {dataLayer.push(arguments)}
    gtag('js', new Date()); gtag('config', 'UA-222107388-1');
  </script>`;

interface AppProps extends React.Component {
  Component: React.FunctionComponent;
  pageProps: any;
  children: React.ReactNode;
}

function App({ Component, pageProps, children }: AppProps) {
  const router = useRouter();

  useEffect(() => {
    ReactGA.initialize(GOOGLE_ANALYTICS_TRACKING_ID);
    const page = window?.location?.pathname;
    ReactGA.send({ hitType: "pageview", page });
  }, []);

  // upon successful login, redirect to the page they were trying to access
  const onRedirectCallback = (appState) => {
    // ensure we have this value and it is not empty
    if (appState?.returnTo && appState?.returnTo.length > 0) {
      router.push(decodeURIComponent(appState?.returnTo));
    }
  };

  useEffect(() => {
    import("react-facebook-pixel")
      .then((x) => x.default)
      .then((ReactPixel) => {
        ReactPixel.init(FACEBOOK_PIXEL_ID);
        ReactPixel.pageView();

        router.events.on("routeChangeComplete", () => {
          ReactPixel.pageView();
        });
      });
  }, [router.events]);

  // if getServerSideProps preloaded any open graph meta tags into the
  // page props inject them into the page head here. This ensures that
  // the values are read by the crawler even before the page body renders
  const openGraphMetaElements = Object.keys(pageProps?.openGraphMeta || {}).map(
    (propertyName) => {
      return (
        <meta
          key={propertyName}
          property={propertyName}
          content={pageProps?.openGraphMeta[propertyName]}
        />
      );
    }
  );

  const BuildProviderTree = (providers) => {
    if (providers.length === 1) {
      return providers[0];
    }
    const A = providers.shift();
    const B = providers.shift();
    return BuildProviderTree([
      ({ children }) => (
        <A>
          <B>{children}</B>
        </A>
      ),
      ...providers,
    ]);
  };
  // First provider is outermost and last provider is innermost
  const Providers = BuildProviderTree([
    Rollbar,
    ThemeProvider,
    SignalProvider,
    AuthProvider,
    RecentMemoriesProvider,
    ChildListProvider,
    RemainingAccountInvitationsProvider,
  ]);

  return (
    <>
      <Head>
        <title>{SITE_TITLE}</title>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        {openGraphMetaElements}
      </Head>

      <Auth0Provider
        domain={LOGIN_DOMAIN}
        clientId={LOGIN_CLIENT_ID}
        cacheLocation={CACHE_LOCATION}
        onRedirectCallback={onRedirectCallback}
        authorizationParams={{
          redirect_uri: LOGIN_REDIRECT_URI,
          audience: API_AUDIENCE,
        }}
      >
        <Providers>
          <WhenReady>
            <Component {...pageProps}>{children}</Component>
          </WhenReady>
        </Providers>
      </Auth0Provider>

      <div dangerouslySetInnerHTML={{ __html: googleAnalyticsHTML }}></div>
    </>
  );
}

export default withDVCProvider({ sdkKey: DVC_CLIENT_SDK_KEY })(App);

import React, { ReactNode } from "react";

// TODO: refactor this CSS out...
import styles from "../../styles/components/ScreenCover.module.css";

interface ScreenCoverProps {
  children: ReactNode;
}
function ScreenCover({ children }: ScreenCoverProps) {
  return <div className={styles.container}>{children}</div>;
}

export default ScreenCover;

import { useAuth0 } from "@auth0/auth0-react";
import { useDVCClient } from "@devcycle/devcycle-react-sdk";
import React, { createContext, Dispatch, ReactNode, useState } from "react";

const API_AUDIENCE = process.env.NEXT_PUBLIC_API_AUDIENCE;

export interface AuthContextProps {
  accessToken?: string;
  setAccessToken?: Dispatch<any>;
  acquireAccessToken?: (
    allowUnauthenticated: boolean,
    showSignUp?: boolean
  ) => Promise<boolean>;
}

const AuthContext = createContext<AuthContextProps>({});

interface AuthProviderProps {
  children: ReactNode;
}

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const dvcClient = useDVCClient();
  const [accessToken, setAccessToken] = useState<string>();

  const { user, getAccessTokenSilently, loginWithRedirect } = useAuth0();

  const triggerLogin = (showSignUp: boolean) => {
    // casing is mixed per the documentation
    const options = {
      appState: {
        // path and query string
        returnTo: encodeURIComponent(
          window?.location?.pathname + window?.location?.search
        ),
      },
      screen_hint: showSignUp ? "signup" : undefined,
    };
    loginWithRedirect(options);
  };

  // This fn returns "true" when it was blocked from taking action either by an
  // existing token or if it is called multiple times in succession.
  let callingForToken = false;
  const acquireAccessToken = async (
    shouldLoginOnFailure: boolean,
    showSignUp?: boolean
  ): Promise<boolean> => {
    if (callingForToken || accessToken) {
      return true;
    }
    callingForToken = true;
    try {
      const tok = await getAccessTokenSilently({
        authorizationParams: {
          audience: API_AUDIENCE,
        },
      });
      // Ensure that we identify the user before moving forward
      const feature_flag_user = {
        user_id: user.sub,
        email: user.email,
      };
      await dvcClient.identifyUser(feature_flag_user);
      setAccessToken(tok);
    } catch (e) {
      // https://community.auth0.com/t/how-do-i-redirect-users-directly-to-the-hosted-signup-page/42520
      if (shouldLoginOnFailure) {
        triggerLogin(showSignUp);
      }
    } finally {
      callingForToken = false;
    }
  };

  return (
    <AuthContext.Provider
      value={{ accessToken, setAccessToken, acquireAccessToken }}
    >
      {children}
    </AuthContext.Provider>
  );
};
export default AuthContext;

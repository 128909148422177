import React, {
  createContext,
  Dispatch,
  ReactNode,
  useContext,
  useState,
} from "react";

import {
  Child,
  MediaUpload,
  Memory,
  User,
} from "../../../libs/ts/models/types";
import * as Api from "../components/API/Api";
import useFeatureFlag from "../components/hooks/useFeatureFlag";
import useMemorySummary, {
  summaryGroupsFromMemoryList,
} from "../components/hooks/useMemorySummary";
import AuthContext from "./Auth";
import {
  MemoryCreatedSignal,
  MemoryDeletedSignal,
  MemoryUpdatedSignal,
} from "./Signals";

// TODO: export this from go
interface RecentMemoriesResp {
  Memories?: Memory[];
  Media?: MediaUpload[];
  Users?: User[];
  Children?: Child[];
}

export interface RecentMemoriesContextProps {
  recentMemoriesData?: RecentMemoriesResp;
  setRecentMemoriesData?: Dispatch<any>;
  loadRecentMemoriesData?: () => void;
  summary?: string;
}

const RecentMemoriesContext = createContext({
  recentMemoriesData: undefined,
  setRecentMemoriesData: undefined,
  loadRecentMemoriesData: undefined,
  summary: undefined,
} as RecentMemoriesContextProps);

interface RecentMemoriesProviderProps {
  children: ReactNode;
}

export const RecentMemoriesProvider = ({
  children,
}: RecentMemoriesProviderProps) => {
  const { accessToken } = useContext(AuthContext);
  const [summary, sumGroupUpdater] = useMemorySummary();
  const recentMemoriesSummary = useFeatureFlag({
    flagName: "recent-memories-ai-summary",
    defaultValue: false,
  });

  const [recentMemoriesData, setRecentMemoriesData] =
    useState<RecentMemoriesResp>();

  let isLoading = false;
  const loadRecentMemoriesData = async (force?: boolean) => {
    if (
      (!accessToken || isLoading || recentMemoriesData !== undefined) &&
      !force
    ) {
      return;
    }
    isLoading = true;
    const recentMemoriesResp = await Api.getRecentMemories({ accessToken });
    const mediaLookup = {};
    recentMemoriesResp.Media.forEach((m) => {
      if (!mediaLookup[m.MemoryID]) {
        mediaLookup[m.MemoryID] = [];
      }
      mediaLookup[m.MemoryID].push(m);
    });

    const childLookup = {};
    recentMemoriesResp.Children.forEach((c) => {
      if (!childLookup[c.ID]) {
        childLookup[c.ID] = [];
      }
      childLookup[c.ID].push(c);
    });

    setRecentMemoriesData({
      mediaLookup,
      childLookup,
      ...recentMemoriesResp,
    });

    if (recentMemoriesSummary) {
      // now that we know the recent memories, render the summary
      sumGroupUpdater(
        summaryGroupsFromMemoryList(recentMemoriesResp?.Memories)
      );
    }

    isLoading = false;
  };

  // subscribe to memory update events
  MemoryUpdatedSignal.useSubscription(() => loadRecentMemoriesData(true));
  MemoryCreatedSignal.useSubscription(() => loadRecentMemoriesData(true));
  MemoryDeletedSignal.useSubscription(() => loadRecentMemoriesData(true));

  return (
    <RecentMemoriesContext.Provider
      value={{
        recentMemoriesData,
        setRecentMemoriesData,
        loadRecentMemoriesData,
        summary,
      }}
    >
      {children}
    </RecentMemoriesContext.Provider>
  );
};
export default RecentMemoriesContext;
